var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.fetchingCheckListData
    ? _c(
        "v-card",
        { attrs: { flat: "", color: "transparent" } },
        [
          _c("v-card-title", [
            _c("div", { staticClass: "d-flex align-center" }, [
              _c("h5", { staticClass: "secondary--text" }, [
                _vm._v("Course checklist"),
              ]),
            ]),
          ]),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-list",
                { attrs: { nav: "", color: "transparent" } },
                [
                  !_vm.fetchingCheckListData
                    ? [
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              "two-line":
                                _vm.screenSize === "large" ||
                                _vm.screenSize === "small",
                              "three-line": _vm.screenSize === "medium",
                            },
                            on: { click: _vm.goToFilesUpload },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "mb-0" },
                              [
                                _c(
                                  "v-badge",
                                  {
                                    attrs: {
                                      avatar: "",
                                      bordered: "",
                                      overlap: "",
                                      bottom: "",
                                      color:
                                        !_vm.filesFetching &&
                                        !_vm.uploadedFilesExist
                                          ? "info"
                                          : _vm.uploadedFilesExist
                                          ? "success"
                                          : "secondary",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "badge",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-avatar",
                                                [
                                                  !_vm.filesFetching &&
                                                  _vm.uploadedFilesExist
                                                    ? _c("v-icon", [
                                                        _vm._v("done"),
                                                      ])
                                                    : _c("v-icon", [
                                                        _vm._v("priority_high"),
                                                      ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      473579771
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-avatar",
                                      { attrs: { size: "40" } },
                                      [
                                        _c("v-icon", { attrs: { large: "" } }, [
                                          _vm._v("file_upload"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _c("h4", { staticClass: "secondary--text" }, [
                                    _vm._v("Upload files"),
                                  ]),
                                ]),
                                !_vm.uploadedFilesExist
                                  ? _c("v-list-item-subtitle", [
                                      _c("span", { staticClass: "caption" }, [
                                        _vm._v("No files uploaded yet"),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "800" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-list-item",
                                        _vm._g(
                                          {
                                            attrs: {
                                              "two-line":
                                                _vm.screenSize === "large" ||
                                                _vm.screenSize === "small",
                                              "three-line":
                                                _vm.screenSize === "medium",
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            { staticClass: "mb-0 py-1" },
                                            [
                                              _c(
                                                "v-badge",
                                                {
                                                  attrs: {
                                                    avatar: "",
                                                    bordered: "",
                                                    overlap: "",
                                                    bottom: "",
                                                    color:
                                                      !_vm.fetchingInstances &&
                                                      !_vm.studentInstancesExists
                                                        ? "info"
                                                        : _vm.studentInstancesExists
                                                        ? "success"
                                                        : "secondary",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "badge",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "v-avatar",
                                                              [
                                                                !_vm.fetchingInstances &&
                                                                _vm.studentInstancesExists
                                                                  ? _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "done"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "priority_high"
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "v-avatar",
                                                    { attrs: { size: "40" } },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { large: "" },
                                                        },
                                                        [_vm._v("person_add")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "secondary--text",
                                                  },
                                                  [_vm._v("Invite students")]
                                                ),
                                              ]),
                                              !_vm.studentInstancesExists
                                                ? _c("v-list-item-subtitle", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "caption",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "No students have joined this course yet"
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2656327207
                            ),
                            model: {
                              value: _vm.inviteDialog,
                              callback: function ($$v) {
                                _vm.inviteDialog = $$v
                              },
                              expression: "inviteDialog",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _c(
                                    "div",
                                    { staticClass: "secondary--text" },
                                    [
                                      _c("v-icon", { staticClass: "mr-1" }, [
                                        _vm._v("person_add"),
                                      ]),
                                      _vm._v(" Invite students "),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("v-divider", { staticClass: "mb-1" }),
                                _c(
                                  "v-card-text",
                                  [_c("TheSpaceInviteFromSnapshot")],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              to:
                                !_vm.fetchingSpaceBundles &&
                                !_vm.distributionBundlesExist
                                  ? {
                                      name: "snapshot-distribute",
                                      params: {
                                        oid: _vm.$route.params.oid,
                                        sid: _vm.$route.params.sid,
                                        iid: _vm.$route.params.iid,
                                        snid: _vm.$route.params.snid,
                                      },
                                    }
                                  : undefined,
                              "two-line":
                                _vm.screenSize === "large" ||
                                _vm.screenSize === "small",
                              "three-line": _vm.screenSize === "medium",
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "mb-0" },
                              [
                                _c(
                                  "v-badge",
                                  {
                                    attrs: {
                                      avatar: "",
                                      bordered: "",
                                      overlap: "",
                                      bottom: "",
                                      color:
                                        !_vm.fetchingSpaceBundles &&
                                        !_vm.distributionBundlesExist
                                          ? "info"
                                          : _vm.distributionBundlesExist
                                          ? "success"
                                          : "secondary",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "badge",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-avatar",
                                                [
                                                  !_vm.fetchingSpaceBundles &&
                                                  _vm.distributionBundlesExist
                                                    ? _c("v-icon", [
                                                        _vm._v("done"),
                                                      ])
                                                    : _c("v-icon", [
                                                        _vm._v("priority_high"),
                                                      ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1380213319
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-avatar",
                                      { attrs: { size: "40" } },
                                      [
                                        _c("v-icon", { attrs: { large: "" } }, [
                                          _vm._v("inventory"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _c("h4", { staticClass: "secondary--text" }, [
                                    _vm._v("Distribute material"),
                                  ]),
                                ]),
                                !_vm.distributionBundlesExist
                                  ? _c("v-list-item-subtitle", [
                                      _c("span", { staticClass: "caption" }, [
                                        _vm._v(
                                          "No material has been shared with students yet"
                                        ),
                                      ]),
                                    ])
                                  : _c("v-list-item-subtitle", [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "caption",
                                          staticStyle: {
                                            "text-decoration": "none",
                                          },
                                          attrs: { href: "#" },
                                          on: {
                                            click: _vm.openDistributedInstance,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "Check what has been distributed"
                                          ),
                                        ]
                                      ),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              "two-line":
                                _vm.screenSize === "large" ||
                                _vm.screenSize === "small",
                              "three-line": _vm.screenSize === "medium",
                            },
                            on: { click: _vm.startAssignmentTour },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "mb-0" },
                              [
                                _c(
                                  "v-badge",
                                  {
                                    attrs: {
                                      avatar: "",
                                      bordered: "",
                                      overlap: "",
                                      bottom: "",
                                      color:
                                        !_vm.fetchingSpaceBundles &&
                                        !_vm.assignmentsExist
                                          ? "info"
                                          : _vm.assignmentsExist
                                          ? "success"
                                          : "secondary",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "badge",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-avatar",
                                                [
                                                  !_vm.fetchingSpaceBundles &&
                                                  _vm.assignmentsExist
                                                    ? _c("v-icon", [
                                                        _vm._v("done"),
                                                      ])
                                                    : _c("v-icon", [
                                                        _vm._v("priority_high"),
                                                      ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1421264410
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-avatar",
                                      { attrs: { size: "40" } },
                                      [
                                        _c("v-icon", { attrs: { large: "" } }, [
                                          _vm._v("assignment"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _c("h4", { staticClass: "secondary--text" }, [
                                    _vm._v("Create assignment"),
                                  ]),
                                ]),
                                !_vm.assignmentsExist
                                  ? _c("v-list-item-subtitle", [
                                      _c("span", { staticClass: "caption" }, [
                                        _vm._v(
                                          "No assignments have been created yet"
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE
                          ? _c(
                              "v-list-item",
                              {
                                attrs: {
                                  to: {
                                    name: "snapshot-applications",
                                  },
                                  "two-line":
                                    _vm.screenSize === "large" ||
                                    _vm.screenSize === "small",
                                  "three-line": _vm.screenSize === "medium",
                                },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  { staticClass: "mb-0" },
                                  [
                                    _c(
                                      "v-badge",
                                      {
                                        attrs: {
                                          avatar: "",
                                          bordered: "",
                                          overlap: "",
                                          bottom: "",
                                          color:
                                            !_vm.fetchingSpaceBundles &&
                                            !_vm.assignmentsExist
                                              ? "info"
                                              : _vm.assignmentsExist
                                              ? "success"
                                              : "secondary",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "badge",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-avatar",
                                                    [
                                                      _vm.schedules.length > 0
                                                        ? _c("v-icon", [
                                                            _vm._v("done"),
                                                          ])
                                                        : _c("v-icon", [
                                                            _vm._v(
                                                              "priority_high"
                                                            ),
                                                          ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          2392944477
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-avatar",
                                          { attrs: { size: "40" } },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { large: "" } },
                                              [_vm._v("schedule_send")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _c(
                                        "h4",
                                        { staticClass: "secondary--text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.schedules.length === 0
                                                ? "Add application startup schedule"
                                                : "Next prestart time"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("v-list-item-subtitle", [
                                      _vm.schedules.length === 0
                                        ? _c(
                                            "span",
                                            { staticClass: "caption" },
                                            [
                                              _vm._v(
                                                "Define class times when applications are prestarted"
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "caption" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("dateTimeToHuman")(
                                                    _vm.schedules[0]
                                                      .startup_time
                                                  )
                                                ) +
                                                  " - " +
                                                  _vm._s(
                                                    _vm.schedules[0].long_id
                                                  )
                                              ),
                                            ]
                                          ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _c(
                        "div",
                        _vm._l(
                          [
                            { id: "c1", opacity: 1 },
                            { id: "c2", opacity: 0.75 },
                            { id: "c3", opacity: 0.5 },
                            { id: "c4", opacity: 0.4 },
                          ],
                          function (item) {
                            return _c(
                              "div",
                              { key: item.id, staticClass: "my-6" },
                              [
                                _c(
                                  "div",
                                  { style: { opacity: item.opacity } },
                                  [
                                    _c("v-skeleton-loader", {
                                      attrs: {
                                        type: "list-item-avatar-two-line",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }